:root {
    --PrimaryColor: rgb(255, 138, 0);
    --OrangeColor: rgb(255, 202, 142);
    --DarkOrangeColor: rgb(255, 115, 0);
    --SecondaryColor: rgb(255,255,255);
    --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
    --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
    --Text: rgb(0, 0, 0);
    --Hover: rgb(80,80,80);
}

.pendaftaran {
    margin-top: 3rem;
    margin: 0 auto;
    min-height: 70vh;
    text-align: justify;
    padding: 20px;
    max-width: 800px;

    .title {
        font-size: 2.5rem;
        position: relative;
        width: max-content;
        z-index: 2;
        margin: auto;
        padding: 0 1rem;
        margin-bottom: 3rem;
        border-bottom: 8px solid var(--PrimaryColor);

        @media screen and (max-width: 1400px) {
            font-size: 2.2rem;
            border-bottom: 7px solid var(--PrimaryColor);
        }

        @media screen and (max-width: 1200px) {
            font-size: 2rem;
            border-bottom: 6px solid var(--PrimaryColor);
        }

        @media screen and (max-width: 700px) {
            font-size: 1.8rem;
            border-bottom: 5px solid var(--PrimaryColor);
        }

        @media screen and (max-width: 500px) {
            font-size: 1.5rem;
            border-bottom: 4px solid var(--PrimaryColor);
        }
    }

    h4 {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 3rem;

        @media screen and (max-width: 1400px) {
            font-size: 1.1rem;
        }

        @media screen and (max-width: 1200px) {
            font-size: 1rem;
        }

        @media screen and (max-width: 700px) {
            font-size: .9rem;
        }

        @media screen and (max-width: 500px) {
            font-size: .8rem;
        }
    }

    .dropdown-btn {
        width: 100%;
        padding: 10px;
        text-align: left;
        background-color: var(--PrimaryColor);
        border: none;
        cursor: pointer;
        outline: none;
        font-size: 1.1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s;
        margin-bottom: 1rem;
        color: white;
        font-weight: bold;
        font-size: 1.2rem;

        @media screen and (max-width: 1400px) {
            font-size: 1.1rem;
        }

        @media screen and (max-width: 1200px) {
            font-size: 1rem;
        }

        @media screen and (max-width: 700px) {
            font-size: .9rem;
        }

        @media screen and (max-width: 500px) {
            font-size: .8rem;
        }

        &:hover {
            background-color: var(--OrangeColor);
        }

        .icon {
            transition: transform 0.3s;
        }

        &.active .icon {
            transform: rotate(180deg);
        }
    }

    .dropdown-container {
        padding: 0 10px;
        display: none;
        overflow: hidden;
        background-color: white;
        border: 1px solid #ccc;
        border-top: none;
        margin-top: -1rem;
        margin-bottom: 2rem;
        transition: max-height 0.5s ease-out, padding 0.5s ease-out;

        &.active {
            max-height: 200px;
            padding: 10px;
        }

        p {
            margin: 0;
            padding: 10px;
            opacity: 0;
            transition: opacity 0.5s ease-out;
            font-size: 1.1rem;

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
            }
    
            @media screen and (max-width: 1200px) {
                font-size: .9rem;
            }
    
            @media screen and (max-width: 700px) {
                font-size: .8rem;
            }
    
            @media screen and (max-width: 500px) {
                font-size: .7rem;
            }

            &.active {
                opacity: 1;
            }
        }
    }
}