:root {
  --PrimaryColor: rgb(255, 138, 0);
  --OrangeColor: rgb(255, 202, 142);
  --DarkOrangeColor: rgb(255, 115, 0);
  --SecondaryColor: rgb(255,255,255);
  --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
  --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
  --Text: rgb(0, 0, 0);
  --Hover: rgb(80,80,80);
}

.ketentuan {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 90vh;
  background-image: url(../../Assets/ketentuanbg.jpg);
  position: relative;
  top: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  background-size: cover;
  background-position: center;
  padding-top: 2rem;
}
.ketentuan .container {
  padding: 1rem 6rem;
}
@media screen and (max-width: 1400px) {
  .ketentuan .container {
    padding: 1rem 5rem;
  }
}
@media screen and (max-width: 1200px) {
  .ketentuan .container {
    padding: 1rem 4rem;
  }
}
@media screen and (max-width: 700px) {
  .ketentuan .container {
    padding: 1rem 3rem;
  }
}
@media screen and (max-width: 500px) {
  .ketentuan .container {
    padding: 1rem 2rem;
  }
}
.ketentuan .container .title {
  font-size: 2.5rem;
  position: relative;
  width: -moz-max-content;
  width: max-content;
  z-index: 2;
  margin: auto;
  padding: 0 1rem;
  margin-bottom: 3rem;
  border-bottom: 8px solid var(--PrimaryColor);
}
@media screen and (max-width: 1400px) {
  .ketentuan .container .title {
    font-size: 2.2rem;
    border-bottom: 7px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 1200px) {
  .ketentuan .container .title {
    font-size: 2rem;
    border-bottom: 6px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 700px) {
  .ketentuan .container .title {
    font-size: 1.8rem;
    border-bottom: 5px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 500px) {
  .ketentuan .container .title {
    font-size: 1.5rem;
    border-bottom: 4px solid var(--PrimaryColor);
  }
}
.ketentuan .container hr {
  width: 80%;
  margin: auto;
}
.ketentuan .container .content {
  width: 80%;
  padding: 1rem;
  margin: auto;
  font-weight: bold;
  font-size: 1.1rem;
}
.ketentuan .container .content:hover {
  background-color: rgba(177, 177, 177, 0.3);
  transition: 0.2s ease-in;
}
@media screen and (max-width: 1400px) {
  .ketentuan .container .content {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .ketentuan .container .content {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 700px) {
  .ketentuan .container .content {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 500px) {
  .ketentuan .container .content {
    font-size: 0.7rem;
  }
}
.ketentuan .container .content span {
  margin-right: 2rem;
}/*# sourceMappingURL=ketentuan.css.map */