:root {
  --PrimaryColor: rgb(255, 138, 0);
  --OrangeColor: rgb(255, 202, 142);
  --DarkOrangeColor: rgb(255, 115, 0);
  --SecondaryColor: rgb(255,255,255);
  --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
  --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
  --Text: rgb(0, 0, 0);
  --Hover: rgb(80,80,80);
}

.pendaftaran {
  margin-top: 3rem;
  margin: 0 auto;
  min-height: 70vh;
  text-align: justify;
  padding: 20px;
  max-width: 800px;
}
.pendaftaran .title {
  font-size: 2.5rem;
  position: relative;
  width: -moz-max-content;
  width: max-content;
  z-index: 2;
  margin: auto;
  padding: 0 1rem;
  margin-bottom: 3rem;
  border-bottom: 8px solid var(--PrimaryColor);
}
@media screen and (max-width: 1400px) {
  .pendaftaran .title {
    font-size: 2.2rem;
    border-bottom: 7px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 1200px) {
  .pendaftaran .title {
    font-size: 2rem;
    border-bottom: 6px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 700px) {
  .pendaftaran .title {
    font-size: 1.8rem;
    border-bottom: 5px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 500px) {
  .pendaftaran .title {
    font-size: 1.5rem;
    border-bottom: 4px solid var(--PrimaryColor);
  }
}
.pendaftaran h4 {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1400px) {
  .pendaftaran h4 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1200px) {
  .pendaftaran h4 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .pendaftaran h4 {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 500px) {
  .pendaftaran h4 {
    font-size: 0.8rem;
  }
}
.pendaftaran .dropdown-btn {
  width: 100%;
  padding: 10px;
  text-align: left;
  background-color: var(--PrimaryColor);
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}
@media screen and (max-width: 1400px) {
  .pendaftaran .dropdown-btn {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1200px) {
  .pendaftaran .dropdown-btn {
    font-size: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .pendaftaran .dropdown-btn {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 500px) {
  .pendaftaran .dropdown-btn {
    font-size: 0.8rem;
  }
}
.pendaftaran .dropdown-btn:hover {
  background-color: var(--OrangeColor);
}
.pendaftaran .dropdown-btn .icon {
  transition: transform 0.3s;
}
.pendaftaran .dropdown-btn.active .icon {
  transform: rotate(180deg);
}
.pendaftaran .dropdown-container {
  padding: 0 10px;
  display: none;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  margin-top: -1rem;
  margin-bottom: 2rem;
  transition: max-height 0.5s ease-out, padding 0.5s ease-out;
}
.pendaftaran .dropdown-container.active {
  max-height: 200px;
  padding: 10px;
}
.pendaftaran .dropdown-container p {
  margin: 0;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  font-size: 1.1rem;
}
@media screen and (max-width: 1400px) {
  .pendaftaran .dropdown-container p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .pendaftaran .dropdown-container p {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 700px) {
  .pendaftaran .dropdown-container p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 500px) {
  .pendaftaran .dropdown-container p {
    font-size: 0.7rem;
  }
}
.pendaftaran .dropdown-container p.active {
  opacity: 1;
}/*# sourceMappingURL=pendaftaran.css.map */