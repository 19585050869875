:root {
  --PrimaryColor: rgb(255, 138, 0);
  --OrangeColor: rgb(255, 202, 142);
  --DarkOrangeColor: rgb(255, 115, 0);
  --SecondaryColor: rgb(255,255,255);
  --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
  --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
  --Text: rgb(0, 0, 0);
  --Hover: rgb(80,80,80);
}

.jadwal {
  background: linear-gradient(180deg, #FFBE72 0%, #FFFFFF 100%);
  padding-top: 5rem;
  min-height: 100vh;
  justify-content: center;
  margin: auto;
  width: 100%;
}
.jadwal .container {
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  padding-bottom: 3rem;
}
.jadwal .container .title {
  position: relative;
  margin: auto;
  background-color: white;
  width: 35rem;
  border: 1px solid black;
  height: 4.5rem;
  padding: 0.3rem 4rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.jadwal .container .title:hover {
  box-shadow: none;
  background-color: var(--OrangeColor);
  transition: 0.1s ease-in;
}
@media screen and (max-width: 1400px) {
  .jadwal .container .title {
    height: 4.3rem;
  }
}
@media screen and (max-width: 1200px) {
  .jadwal .container .title {
    width: 30rem;
    height: 4rem;
  }
}
@media screen and (max-width: 700px) {
  .jadwal .container .title {
    width: 25rem;
    height: 3.7rem;
  }
}
@media screen and (max-width: 500px) {
  .jadwal .container .title {
    width: 20rem;
    height: 3rem;
  }
}
.jadwal .container .title h1 {
  font-size: 2.25rem;
  border-bottom: 7px solid var(--PrimaryColor);
}
@media screen and (max-width: 1400px) {
  .jadwal .container .title h1 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 1200px) {
  .jadwal .container .title h1 {
    font-size: 2rem;
    border-bottom: 6px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 700px) {
  .jadwal .container .title h1 {
    font-size: 1.8rem;
    border-bottom: 5px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 500px) {
  .jadwal .container .title h1 {
    font-size: 1.5rem;
    border-bottom: 4px solid var(--PrimaryColor);
  }
}
.jadwal .container .content {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
}
@media screen and (max-width: 1200px) {
  .jadwal .container .content {
    gap: 4rem;
  }
}
@media screen and (max-width: 885px) {
  .jadwal .container .content {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}
@media screen and (max-width: 700px) {
  .jadwal .container .content {
    gap: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .jadwal .container .content {
    gap: 1rem;
  }
}
.jadwal .container .content .textContent {
  padding: 2rem;
  background-color: white;
  border: 1px solid black;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 12rem;
  position: relative;
  margin: auto;
  height: auto;
  font-size: 1.1rem;
  font-weight: 500;
}
.jadwal .container .content .textContent:hover {
  box-shadow: none;
  background-color: var(--OrangeColor);
  transition: 0.1s ease-in;
}
@media screen and (max-width: 1400px) {
  .jadwal .container .content .textContent {
    font-size: 1rem;
    width: 11rem;
  }
}
@media screen and (max-width: 1200px) {
  .jadwal .container .content .textContent {
    font-size: 0.9rem;
    width: 10rem;
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 700px) {
  .jadwal .container .content .textContent {
    font-size: 0.8rem;
    width: 8rem;
    padding: 1.5rem 1rem;
  }
}
@media screen and (max-width: 500px) {
  .jadwal .container .content .textContent {
    font-size: 0.7rem;
    padding: 1.4rem 1rem;
  }
}
.jadwal .container .content .textContent .day {
  background-color: var(--PrimaryColor);
  border-radius: 20px;
  font-weight: bold;
  color: white;
  padding: 0.3rem 0;
  margin-bottom: 1rem;
}/*# sourceMappingURL=jadwal.css.map */