:root {
    --PrimaryColor: rgb(255, 138, 0);
    --OrangeColor: rgb(255, 202, 142);
    --DarkOrangeColor: rgb(255, 115, 0);
    --SecondaryColor: rgb(255,255,255);
    --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
    --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
    --Text: rgb(0, 0, 0);
    --Hover: rgb(80,80,80);
}

.tentang {
    margin-top: 3rem;
    width: 100%;
    position: relative;
    display: grid;
    justify-content: center;
    margin: auto;
    min-height: 100vh;
    padding: 2.5rem 8rem;
    text-align: justify;

    .title {
        font-size: 2.5rem;
        position: relative;
        width: max-content;
        z-index: 2;
        margin: auto;
        padding: 0 1rem;
        margin-bottom: 3rem;
        border-bottom: 8px solid var(--PrimaryColor);

        @media screen and (max-width: 1400px) {
            font-size: 2.2rem;
            border-bottom: 7px solid var(--PrimaryColor);
        }

        @media screen and (max-width: 1200px) {
            font-size: 2rem;
            border-bottom: 6px solid var(--PrimaryColor);
        }

        @media screen and (max-width: 700px) {
            font-size: 1.8rem;
            border-bottom: 5px solid var(--PrimaryColor);
        }

        @media screen and (max-width: 500px) {
            font-size: 1.5rem;
            border-bottom: 4px solid var(--PrimaryColor);
        }
    }

    img {
        width: 25%;
    }

    .kenapa {
        flex-direction: row-reverse;
    }

    .textContent {
        margin: 2rem;
        
        h3 {
            font-size: 2rem;

            span {
                font-family: "Just Another Hand", cursive;
                margin-left: .5rem;

                .IFE {
                    color: var(--PrimaryColor);
                }
            }

            @media screen and (max-width: 1200px) {
                font-size: 1.8rem;
            }

            @media screen and (max-width: 1000px) {
                font-size: 1.5rem;
            }

            @media screen and (max-width: 885px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 600px) {
                font-size: 1.3rem;
            }

            @media screen and (max-width: 430px) {
                font-size: 1.2rem;
            }
        }

        h4 {
            font-size: 1.5rem;
            margin-top: .5rem;

            @media screen and (max-width: 1200px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 1000px) {
                font-size: 1.3rem;
            }

            @media screen and (max-width: 885px) {
                font-size: 1.2rem;
            }

            @media screen and (max-width: 600px) {
                font-size: 1.1rem;
            }

            @media screen and (max-width: 430px) {
                font-size: 1rem;
            }
        }

        p {
            font-size: 1.2rem;

            @media screen and (max-width: 1200px) {
                font-size: 1.1rem;
            }

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            @media screen and (max-width: 885px) {
                font-size: .9rem;
            }

            @media screen and (max-width: 600px) {
                font-size: .8rem;
            }

            @media screen and (max-width: 430px) {
                font-size: .7rem;
            }
        }

        ul {
            font-size: 1.2rem;
            
            li {
                list-style: disc;
                margin-left: 1.2rem;
            }

            @media screen and (max-width: 1200px) {
                font-size: 1.1rem;
            }

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            @media screen and (max-width: 885px) {
                font-size: .9rem;
            }

            @media screen and (max-width: 600px) {
                font-size: .8rem;
            }

            @media screen and (max-width: 430px) {
                font-size: .7rem;
            }
        }

        @media screen and (max-width: 885px) {
            margin: 2rem 0;
        }
    }

    @media screen and (max-width: 885px) {
        padding: 2.5rem 3.5rem;

        .apa, .kenapa, .visimisi {
            display: grid !important;
            margin-left: 0;
        }

        img {
            width: 100%;
        }
    }
}