:root {
    --PrimaryColor: rgb(255, 138, 0);
    --OrangeColor: rgb(255, 202, 142);
    --DarkOrangeColor: rgb(255, 115, 0);
    --SecondaryColor: rgb(255,255,255);
    --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
    --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
    --Text: rgb(0, 0, 0);
    --Hover: rgb(80,80,80);
}

.ketentuan {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-height: 90vh;
    background-image: url(../../Assets/ketentuanbg.jpg);
    position: relative;
    top: 0;
    bottom: 0;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    padding-top: 2rem;

    .container {
        padding: 1rem 6rem;

        @media screen and (max-width: 1400px) {
            padding: 1rem 5rem;
        }

        @media screen and (max-width: 1200px) {
            padding: 1rem 4rem;
        }

        @media screen and (max-width: 700px) {
            padding: 1rem 3rem;
        }

        @media screen and (max-width: 500px) {
            padding: 1rem 2rem;
        }

        .title {
            font-size: 2.5rem;
            position: relative;
            width: max-content;
            z-index: 2;
            margin: auto;
            padding: 0 1rem;
            margin-bottom: 3rem;
            border-bottom: 8px solid var(--PrimaryColor);
    
            @media screen and (max-width: 1400px) {
                font-size: 2.2rem;
                border-bottom: 7px solid var(--PrimaryColor);
            }
    
            @media screen and (max-width: 1200px) {
                font-size: 2rem;
                border-bottom: 6px solid var(--PrimaryColor);
            }
    
            @media screen and (max-width: 700px) {
                font-size: 1.8rem;
                border-bottom: 5px solid var(--PrimaryColor);
            }
    
            @media screen and (max-width: 500px) {
                font-size: 1.5rem;
                border-bottom: 4px solid var(--PrimaryColor);
            }
        }

        hr {
            width: 80%;
            margin: auto;
        }
        
        .content {
            width: 80%;
            padding: 1rem;
            margin: auto;
            font-weight: bold;
            font-size: 1.1rem;

            &:hover {
                background-color: rgba(177, 177, 177, 0.3);
                transition: .2s ease-in;
            }

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
            }
    
            @media screen and (max-width: 1200px) {
                font-size: .9rem;
            }
    
            @media screen and (max-width: 700px) {
                font-size: .8rem;
            }
    
            @media screen and (max-width: 500px) {
                font-size: .7rem;
            }

            span {
                margin-right: 2rem;
            }
        }
    }
}