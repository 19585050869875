:root {
  --PrimaryColor: rgb(255, 138, 0);
  --OrangeColor: rgb(255, 202, 142);
  --DarkOrangeColor: rgb(255, 115, 0);
  --SecondaryColor: rgb(255,255,255);
  --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
  --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
  --Text: rgb(0, 0, 0);
  --Hover: rgb(80,80,80);
}

.tentang {
  margin-top: 3rem;
  width: 100%;
  position: relative;
  display: grid;
  justify-content: center;
  margin: auto;
  min-height: 100vh;
  padding: 2.5rem 8rem;
  text-align: justify;
}
.tentang .title {
  font-size: 2.5rem;
  position: relative;
  width: -moz-max-content;
  width: max-content;
  z-index: 2;
  margin: auto;
  padding: 0 1rem;
  margin-bottom: 3rem;
  border-bottom: 8px solid var(--PrimaryColor);
}
@media screen and (max-width: 1400px) {
  .tentang .title {
    font-size: 2.2rem;
    border-bottom: 7px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 1200px) {
  .tentang .title {
    font-size: 2rem;
    border-bottom: 6px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 700px) {
  .tentang .title {
    font-size: 1.8rem;
    border-bottom: 5px solid var(--PrimaryColor);
  }
}
@media screen and (max-width: 500px) {
  .tentang .title {
    font-size: 1.5rem;
    border-bottom: 4px solid var(--PrimaryColor);
  }
}
.tentang img {
  width: 25%;
}
.tentang .kenapa {
  flex-direction: row-reverse;
}
.tentang .textContent {
  margin: 2rem;
}
.tentang .textContent h3 {
  font-size: 2rem;
}
.tentang .textContent h3 span {
  font-family: "Just Another Hand", cursive;
  margin-left: 0.5rem;
}
.tentang .textContent h3 span .IFE {
  color: var(--PrimaryColor);
}
@media screen and (max-width: 1200px) {
  .tentang .textContent h3 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 1000px) {
  .tentang .textContent h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 885px) {
  .tentang .textContent h3 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 600px) {
  .tentang .textContent h3 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 430px) {
  .tentang .textContent h3 {
    font-size: 1.2rem;
  }
}
.tentang .textContent h4 {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .tentang .textContent h4 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1000px) {
  .tentang .textContent h4 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 885px) {
  .tentang .textContent h4 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 600px) {
  .tentang .textContent h4 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 430px) {
  .tentang .textContent h4 {
    font-size: 1rem;
  }
}
.tentang .textContent p {
  font-size: 1.2rem;
}
@media screen and (max-width: 1200px) {
  .tentang .textContent p {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1000px) {
  .tentang .textContent p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 885px) {
  .tentang .textContent p {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 600px) {
  .tentang .textContent p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 430px) {
  .tentang .textContent p {
    font-size: 0.7rem;
  }
}
.tentang .textContent ul {
  font-size: 1.2rem;
}
.tentang .textContent ul li {
  list-style: disc;
  margin-left: 1.2rem;
}
@media screen and (max-width: 1200px) {
  .tentang .textContent ul {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1000px) {
  .tentang .textContent ul {
    font-size: 1rem;
  }
}
@media screen and (max-width: 885px) {
  .tentang .textContent ul {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 600px) {
  .tentang .textContent ul {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 430px) {
  .tentang .textContent ul {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 885px) {
  .tentang .textContent {
    margin: 2rem 0;
  }
}
@media screen and (max-width: 885px) {
  .tentang {
    padding: 2.5rem 3.5rem;
  }
  .tentang .apa, .tentang .kenapa, .tentang .visimisi {
    display: grid !important;
    margin-left: 0;
  }
  .tentang img {
    width: 100%;
  }
}/*# sourceMappingURL=tentang.css.map */