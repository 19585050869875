:root {
    --PrimaryColor: rgb(255, 138, 0);
    --OrangeColor: rgb(255, 202, 142);
    --DarkOrangeColor: rgb(255, 115, 0);
    --SecondaryColor: rgb(255,255,255);
    --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
    --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
    --Text: rgb(0, 0, 0);
    --Hover: rgb(80,80,80);
}

.footer {
    background: #4A4A4A;
    min-height: 50vh;
    justify-content: center;
    align-items: center;
    top: 0;
    margin-top: 0;

    .content {
        justify-content: space-around;
        align-items: start;
        margin: auto;
        padding: 2rem;

        .logo {
            color: var(--Text);
            font-weight: 600;
            display: flex;
    
            .icon {
                width: 10rem;

                @media screen and (max-width: 885px) {
                    width: 6rem;
                }
            }
    
            h1 {
                font-family: "Just Another Hand", cursive;
                font-size: 2.8rem;
                margin-left: 15px;
                margin-bottom: auto;
                transition: .5s ease-in;

                span {
                    font-family: "Just Another Hand", cursive;
                    margin-left: .7rem;
                    color: rgb(255, 138, 0);
                    transition: .5s ease-in;
                }
            }
    
            h4 {
                font-size: .78rem;
                margin-left: 15px;
                font-weight: 800 !important;
                margin-top: -8px;
                color: #ffffff;
                transition: .5s ease-in;
            }
        }
    
        h3 {
            color: var(--PrimaryColor);
            font-size: 1.2rem;
            margin-bottom: 1rem;

            @media screen and (max-width: 1400px) {
                font-size: 1.2rem;
            }
    
            @media screen and (max-width: 1200px) {
                font-size: 1.1rem;
            }
    
            @media screen and (max-width: 700px) {
                font-size: 1rem;
            }
    
            @media screen and (max-width: 500px) {
                font-size: .9rem;
            }
        }
    
        a {
            color: white;
            font-size: 1rem;
            justify-items: center;
            align-items: center;
            display: flex;
            margin-top: .5rem;

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
            }
    
            @media screen and (max-width: 1200px) {
                font-size: .9rem;
            }
    
            @media screen and (max-width: 700px) {
                font-size: .8rem;
            }
    
            @media screen and (max-width: 500px) {
                font-size: .7rem;
            }

            &:hover {
                color: var(--PrimaryColor);
            }

            .icon {
                margin-right: .5rem;
            }
        }
    
        h5 {
            margin-top: .5rem;
            font-size: 1rem;
            color: white;

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
            }
    
            @media screen and (max-width: 1200px) {
                font-size: .9rem;
            }
    
            @media screen and (max-width: 700px) {
                font-size: .8rem;
            }
    
            @media screen and (max-width: 500px) {
                font-size: .7rem;
            }
        }
    }

    .footerDiv {
        text-align: center;
        background-color: var(--PrimaryColor);
        color: white;
        font-weight: bold;
        margin-top: 6rem;
        height: 2rem;
        padding-top: 5px;
    }

    @media screen and (max-width: 1200px) {
        .content {
            justify-content: center;
            display: grid;
            margin: auto;
            grid-template-columns: repeat(2,1fr);

            h3 {
                margin-top: 2rem;
            }
        }
    }

    @media screen and (max-width: 885px) {
        .content {
            align-items: center;
            grid-template-columns: repeat(1,1fr);
        }
    }
}