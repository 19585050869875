@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Mada:wght@200..900&display=swap');

:root {
  --PrimaryColor: rgb(255, 138, 0);
  --OrangeColor: rgb(255, 202, 142);
  --DarkOrangeColor: rgb(255, 115, 0);
  --SecondaryColor: rgb(255,255,255);
  --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
  --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
  --Text: rgb(0, 0, 0);
  --Hover: rgb(80,80,80);
}

::selection {
  background: #FF8A00;
  color: white;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Mada", sans-serif;
}

.App {
  color: var(--Text);
  background-color: var(--SecondaryColor);
  font-size: large;
  font-weight: bold;
}

html {
  scroll-behavior: smooth;
  background-color: var(--SecondaryColor);
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.btn {
  padding: .6rem 1.5rem;
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a {
  font-weight: 500;
}

img, video {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
}

body {
  background: var(--SecondaryColor);
}

/* MEDIA QUERIES */
@media screen and (min-width: 1240px) {
  .section {
      padding: 6rem 0 4rem;
  }
}

@media screen and (min-width: 2560px) {
  body {
      zoom: 1.7;
  }
}